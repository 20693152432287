
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
} from "@ionic/vue";
import { phonePortraitOutline } from "ionicons/icons";
import { defineComponent, ref, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useLocalStorage } from "@/libs/localStorage";
import settingsApp from "@/libs//settings";
import sessionApp from "@/libs/session";
import httpApp from "@/libs/http";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useStore } from "@/store";

import AccountNavComponent from "./AccountNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

export default defineComponent({
  name: "Mobile",
  setup() {
    const { t, te, tm } = useI18n();
    const { createLoading, openToast } = useGlobalFunctions();
    const { getLocalStorageData } = useLocalStorage();
    const router = useRouter();
    const storeX = useStore();
    const isLoggedIn = ref(false);
    const customerData = ref(null);
    const icons = reactive({
      phonePortraitOutline,
    });

    const domainOriginUrl = window.location.origin;
    const isAE = domainOriginUrl.includes("ae.yallatager");
    const countryCode = ref(isAE ? "AE" : "EG");

    const editAccountData = reactive({
      mobile_no: storeX.state.customerData.account_data.phone,
      mobileValidated:
        storeX.state.customerData.account_data.phone_verified == 1
          ? true
          : false,
    });
    const editAccountDataRules = {
      mobile_no: {
        required,
      },
    };
    const vEditAccountData = useVuelidate(
      editAccountDataRules,
      editAccountData
    ) as any;

    function setCustomerData(customerDataParm: any) {
      customerData.value = customerDataParm;
    }

    const onTelInput = (phone, phoneObject: any) => {
      if (phoneObject) {
        if (Object.prototype.hasOwnProperty.call(phoneObject, "number")) {
          editAccountData.mobile_no = phoneObject.number;
        }
        if (Object.prototype.hasOwnProperty.call(phoneObject, "valid")) {
          editAccountData.mobileValidated = phoneObject.valid;
        }
      }
    };

    const saveAccountAction = async (event: any) => {
      event.preventDefault();
      vEditAccountData.value.$touch();
      if (vEditAccountData.value.$invalid) {
        return false;
      }
      if (!editAccountData.mobileValidated) {
        return false;
      }
      const sentData = {
        mobile_no: editAccountData.mobile_no,
      };
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("editMobile"), sentData)
        .then(async (response: any) => {
          if (response.success) {
            sessionApp.getCustomerData().then(
              (response: any) => {
                storeX.commit("setCustomerData", response);
                openToast(t("done"), "success");
                loading.dismiss();
              },
              () => {
                loading.dismiss();
                router.push("/account/");
              }
            );
          } else {
            if (te("apiMsgs." + response.error.code)) {
              openToast(t("apiMsgs." + response.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
            loading.dismiss();
          }
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error, "error");
        });
    };

    const resendCount = ref(60);
    watch(
      resendCount,
      (val) => {
        if (val > 0) {
          setTimeout(() => {
            resendCount.value -= 1;
          }, 1000);
        }
      },
      { immediate: true }
    );

    const verifyMobileData = ref({
      verify_code: "",
    });
    const verifyMobileRules = {
      verify_code: {
        required,
      },
    };
    const vVerifyMobileData = useVuelidate(
      verifyMobileRules,
      verifyMobileData
    ) as any;

    const resendCode = async (event) => {
      event.preventDefault();
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendGetRequest(settingsApp.getEndpointUrl("resendVerificationCode"))
        .then(async (response: any) => {
          if (response.success) {
            openToast(t("done"), "success");
            resendCount.value = 60;
          } else {
            if (te("apiMsgs." + response.error.code)) {
              openToast(t("apiMsgs." + response.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
          }
          loading.dismiss();
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error, "error");
        });
    };

    const verifyCode = async (event) => {
      event.preventDefault();
      vVerifyMobileData.value.$touch();
      if (vVerifyMobileData.value.$invalid) {
        return false;
      }
      const sentData = {
        verify_code: verifyMobileData.value.verify_code,
      };
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("verifyVerificationCode"),
          sentData
        )
        .then(async (response: any) => {
          if (response.success) {
            sessionApp.getCustomerData().then(
              (response: any) => {
                storeX.commit("setCustomerData", response);
                openToast(t("done"), "success");
                loading.dismiss();
              },
              () => {
                loading.dismiss();
                router.push("/account/");
              }
            );
          } else {
            if (te("apiMsgs." + response.error.code)) {
              openToast(t("apiMsgs." + response.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
            loading.dismiss();
          }
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error, "error");
        });
    };

    const resendBtnText = (count) => {
      let text = "";
      if (count > 0) {
        text = "(" + count + ") ";
      }
      text += t("account.resendCode");
      return text;
    };

    function ionViewWillEnter() {
      if (sessionApp.islogged()) {
        const sessionCustomerData = getLocalStorageData("customerData");
        isLoggedIn.value = true;
        setCustomerData(sessionCustomerData.account_data);
        storeX.commit("setCustomerData", sessionCustomerData);
      } else {
        storeX.commit("setCustomerData", null);
      }
    }

    return {
      t,
      tm,
      storeX,
      isLoggedIn,
      editAccountData,
      customerData,
      ionViewWillEnter,
      icons,
      countryCode,
      vEditAccountData,
      saveAccountAction,
      onTelInput,
      resendCount,
      verifyMobileData,
      vVerifyMobileData,
      resendCode,
      resendBtnText,
      verifyCode,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    VueTelInput,
    IonInput,
    IonButton,
    AccountNavComponent,
    MainHeader,
    MainFooter,
  },
});

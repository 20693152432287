import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main pages" }
const _hoisted_2 = { class: "page-content pt-100 pb-100" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-10 m-auto" }
const _hoisted_6 = { class: "row d-block" }
const _hoisted_7 = { class: "col-md-3 float-start" }
const _hoisted_8 = { class: "col-md-9 float-start positionRelative account" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-lg-12" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "card-body form-group" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-lg-6" }
const _hoisted_17 = {
  key: 0,
  class: "card"
}
const _hoisted_18 = { class: "card-header" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-lg-12" }
const _hoisted_21 = { class: "mb-0" }
const _hoisted_22 = { class: "card-body form-group" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-sm-6" }
const _hoisted_25 = { class: "col-sm-6" }
const _hoisted_26 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_AccountNavComponent = _resolveComponent("AccountNavComponent")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_AccountNavComponent)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.t("account.editMobile")), 1)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_ion_list, { class: "formList" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, {
                                  class: _normalizeClass(["form-control", {
                              'is-invalid':
                                _ctx.vEditAccountData.mobile_no.$errors.length,
                            }]),
                                  lines: "none"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          icon: _ctx.icons.phonePortraitOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_vue_tel_input, {
                                      onInput: _ctx.onTelInput,
                                      class: _normalizeClass(["form-control", {
                                'is-invalid':
                                  _ctx.vEditAccountData.mobile_no.$errors.length,
                              }]),
                                      mode: "international",
                                      defaultCountry: _ctx.countryCode,
                                      value: _ctx.editAccountData.mobile_no,
                                      inputOptions: {
                                placeholder: _ctx.t('account.mobile'),
                              }
                                    }, null, 8, ["onInput", "defaultCountry", "value", "inputOptions", "class"])
                                  ]),
                                  _: 1
                                }, 8, ["class"])
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("a", {
                                  href: "javascript:void(0)",
                                  class: "btn btn-heading btn-block",
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveAccountAction($event)))
                                }, _toDisplayString(_ctx.t("save")), 1)
                              ])
                            ])
                          ])
                        ]),
                        (
                        _ctx.storeX.state.customerData.account_data.phone_verified ==
                        '0'
                      )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", _hoisted_19, [
                                  _createElementVNode("div", _hoisted_20, [
                                    _createElementVNode("h3", _hoisted_21, _toDisplayString(_ctx.t("account.verify")), 1)
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_22, [
                                _createVNode(_component_ion_list, { class: "formList" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_item, {
                                      class: _normalizeClass(["form-control", {
                              'is-invalid':
                                _ctx.vVerifyMobileData.verify_code.$errors.length,
                            }]),
                                      lines: "none"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_input, {
                                          modelValue: _ctx.verifyMobileData.verify_code,
                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.verifyMobileData.verify_code) = $event)),
                                          clearInput: "true",
                                          inputmode: "text",
                                          type: "text",
                                          placeholder: _ctx.t('account.verifyCode')
                                        }, null, 8, ["modelValue", "placeholder"])
                                      ]),
                                      _: 1
                                    }, 8, ["class"])
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("div", _hoisted_23, [
                                  _createElementVNode("div", _hoisted_24, [
                                    _createElementVNode("a", {
                                      href: "javascript:void(0)",
                                      class: "btn btn-heading btn-block",
                                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.verifyCode($event)))
                                    }, _toDisplayString(_ctx.t("account.verify")), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_25, [
                                    _createVNode(_component_ion_button, {
                                      class: "ion-float-end outline",
                                      slot: "end",
                                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resendCode($event))),
                                      textContent: _toDisplayString(_ctx.resendBtnText(_ctx.resendCount)),
                                      disabled: _ctx.resendCount > 0
                                    }, null, 8, ["textContent", "disabled"])
                                  ])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("footer", _hoisted_26, [
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}